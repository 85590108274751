import { INPUT_TYPE } from "../constants";

export const NOVEL_TYPE = [
  {
    novelType: "xuanhuan",
    displayName: "玄幻",
  },
  {
    novelType: "lishi",
    displayName: "历史",
  },
  {
    novelType: "xuanyi",
    displayName: "悬疑",
  },
  {
    novelType: "wenxue",
    displayName: "文学",
  },
  {
    novelType: "qingchun",
    displayName: "青春",
  },
];

export const CREATE_NOVEL_CONFIGS = [
  {
    fieldName: "title",
    label: "title",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "author",
    label: "author",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "description",
    label: "description",
    inputType: INPUT_TYPE.TEXT_AREA,
  },
  {
    fieldName: "novelType",
    label: "novelType",
    enumValues: ["", ...NOVEL_TYPE.map((i) => i.displayName)],
    inputType: INPUT_TYPE.SELECT,
  },
  {
    fieldName: "attachment",
    label: "coverImage",
    inputType: INPUT_TYPE.FILE,
    fileTypes: ["jpeg", "jpg", "png"],
  },
];
